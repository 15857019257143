import React, { useEffect, useState } from 'react';
import './App.scss';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import NavBar from './components/NavBar/NavBar';
import Login from './components/Login/Login';
import AuthRedirect from './auth/AuthRedirect';
import { refreshSession, signOutUser } from './auth/cognito-utils';
import {
  LandingPage,
  MyBrewsPage,
  GenerateBrewLogoPage,
  MyNotesPage,
  ViewBrewsPage,
  ViewNotesPage,
} from 'src/pages';

export default function App() {
  const [mode] = React.useState('light');
  const defaultTheme = createTheme({ palette: { mode } });
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await refreshSession();
        setIsAuthenticated(true);
        // Only navigate to /mybrews if the user is on the root path
        if (location.pathname === '/') {
          navigate('/mybrews');
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, [navigate, location.pathname]);

  const handleSignOut = () => {
    signOutUser();
    setIsAuthenticated(false);
    navigate('/');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <NavBar mode={mode} isAuthenticated={isAuthenticated} onSignOut={handleSignOut} />
      <Routes>
        <Route path="/" element={< LandingPage />} />
        <Route
          path="/signup"
          element={
            <AuthRedirect>
              <Login isSignUp={true} />
            </AuthRedirect>
          }
        />
        <Route
          path="/signin"
          element={
            <AuthRedirect>
              <Login isSignUp={false} />
            </AuthRedirect>
          }
        />
        <Route path="/mybrews" element={isAuthenticated ? <MyBrewsPage /> : <Login isSignUp={false} />} />
        <Route path="/generate-brew-logo" element={isAuthenticated ? <GenerateBrewLogoPage /> : <Login isSignUp={false} />} />
        <Route path="/mynotes" element={isAuthenticated ? <MyNotesPage /> : <Login isSignUp={false} />} />
        <Route path="/viewbrews/:breweryName" element={<ViewBrewsPage />} />
        <Route path="/viewnotes" element={isAuthenticated ? <ViewNotesPage /> : <Login isSignUp={false} />} />
      </Routes>
    </ThemeProvider>
  );
}

