import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { poolData } from './cognito-config';

const userPool = new CognitoUserPool(poolData);

export const getAuthToken = () => {
  return localStorage.getItem('idToken');
};

export const clearAuthToken = () => {
  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('username');
};

export const signOutUser = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
    clearAuthToken();
  }
};
