import React, { useState, useEffect, useCallback } from 'react';
import AddBrewForm from '../AddBrewForm/AddBrewForm';
import { deleteBrew, updateBrewOrder, getUserBrews, getBrewsByBreweryName } from '../../api/api-helper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// import Skeleton from '@mui/material/Skeleton';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { arrayMove, SortableItem } from '../SortableItem/SortableItem';
import './BrewList.css';
import { AddBrewBtn, BrewModal, BrewItem } from 'src/components';

const BrewList = ({ onBrewAdded, isViewOnly = false, breweryName = null }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [brewList, setBrewList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingImages, setLoadingImages] = useState({});
  const token = localStorage.getItem('idToken'); // Ensure you use the correct key

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 10 } })
  );

  const fetchBrews = useCallback(async () => {
    try {
      const data = isViewOnly && breweryName ? await getBrewsByBreweryName(breweryName) : await getUserBrews(token);
      const sortedBrews = data.brews.sort((a, b) => a.order - b.order);
      setBrewList(sortedBrews);
    } catch (error) {
      console.error('Error fetching brews:', error);
    }
  }, [token, isViewOnly, breweryName]);

  useEffect(() => {
    fetchBrews();
  }, [fetchBrews]);

  const handleAddBrewClick = () => {
    setIsAdding(true);
  };

  const handleClose = () => {
    setIsAdding(false);
  };

  const handleDelete = async (brewId) => {
    if (window.confirm('Are you sure you want to delete this brew?')) {
      try {
        await deleteBrew(brewId, token);
        fetchBrews(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting brew:', error);
      }
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setBrewList((items) => {
        const oldIndex = items.findIndex((item) => item.brewId === active.id);
        const newIndex = items.findIndex((item) => item.brewId === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleSaveOrder = useCallback(async () => {
    setIsSaving(true);
    try {
      await updateBrewOrder(brewList, token);
      alert('Brew order saved successfully');
    } catch (error) {
      console.error('Error saving brew order:', error);
    } finally {
      setIsSaving(false);
    }
  }, [brewList, token]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        handleSaveOrder();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSaveOrder]);

  const handleImageLoad = (brewId) => {
    setLoadingImages((prev) => ({ ...prev, [brewId]: false }));
  };

  return (
    <div className="brew-list">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={brewList} strategy={verticalListSortingStrategy}>
          {brewList.map((brew) => (
            <SortableItem key={brew.brewId} id={brew.brewId}>
              <BrewItem
                brew={brew}
                isViewOnly={isViewOnly}
                loadingImages={loadingImages}
                handleImageLoad={handleImageLoad}
                handleDelete={handleDelete}
              />
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
      {!isViewOnly && (
        <>
          <AddBrewBtn
            handleAddBrewClick={handleAddBrewClick}
          />
          <div className="save-order-container">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveOrder}
              disabled={isSaving}
              startIcon={isSaving && <CircularProgress size={20} />}
            >
              Save Order
            </Button>
          </div>
        </>
      )}

      <BrewModal
        isOpen={isAdding}
        title='Add a New Brew'
        onClose={handleClose}
      >
        <AddBrewForm
          onBrewAdded={fetchBrews}
          onClose={handleClose}
        />
      </BrewModal>
    </div>
  );
};

export default BrewList;
