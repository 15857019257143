import React, { useState, useEffect } from 'react';
import { getUserBrews } from '../../api/api-helper';
import { Link } from 'react-router-dom';
import './style.css';

const MyNotesPage = () => {
  const [brews, setBrews] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('idToken'); // Ensure you use the correct key

  useEffect(() => {
    const fetchBrews = async () => {
      try {
        if (token) {
          const data = await getUserBrews(token);
          setBrews(data.brews);
        } else {
          console.error('No token found in localStorage');
        }
      } catch (error) {
        console.error('Error fetching brews:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBrews();
  }, [token]);

  if (loading) {
    return (
      <div className="loading-container">
        Loading...
      </div>
    );
  }

  return (
    <div className="my-notes-page">
      <h1>My Notes</h1>
      <table className="notes-table">
        <thead>
          <tr>
            <th>Brew Name</th>
            <th>Last Updated</th>
            <th>Brew Type</th>
          </tr>
        </thead>
        <tbody>
          {brews.map(brew => (
            <tr key={brew.brewId}>
              <td>
                <Link to={`/viewnotes?brewId=${brew.brewId}`}>
                  {brew.name}
                </Link>
              </td>
              <td>{new Date(brew.lastUpdated).toLocaleDateString()}</td>
              <td>{brew.brewType}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="add-note-button">Add New Brew</button>
    </div>
  );
};

export default MyNotesPage;
