import React from 'react';
import { Container } from '@mui/material';
import './style.scss';
import { Feature, LastFeature } from '../../Common';
import logo1 from 'src/Assets/images/Landing/paper-plane-icon.png';
import logo2 from 'src/Assets/images/Landing/poster-icon.png';
import logo3 from 'src/Assets/images/Landing/cashflow.png';

const features = [
  {
    logo: logo1,
    tag: 'Share',
    title: 'Create and share your brew collection',
    description: 'Easily create a webpage to show photos and descriptions of your brews (beer, wine, mead, kombucha). No web development skills required.'
  },
  {
    logo: logo2,
    tag: 'Document',
    title: 'Document your brewing process',
    description: 'Take detailed notes on each step of your brewing process. Keep all your notes in one place and access them easily with our web app.'
  }
];

const lastFeature = {
  logo: logo3,
  tag: 'Simplify',
  // title: 'Manage your brewing journey',
  description: 'MyBrewPage is designed specifically for homebrewers looking to grow their hobby. No app to install, and simple online access from your computer or smartphone'
};

const Features = () => {
  return (
    <Container maxWidth="lg" className="features-section">
      <h2>Features</h2>
      <div className='features-content'>
        <div className='features-content-1'>
          {features.map((item, index) => (
            <Feature
              key={index}
              tag={item.tag}
              title={item.title}
              description={item.description}
              logo={item.logo}
            />
          ))}
        </div>
        <div className='last-feature'>
          <LastFeature
            tag={lastFeature.tag}
            title={lastFeature.title}
            description={lastFeature.description}
            logo={lastFeature.logo}
          />
        </div>
      </div>
    </Container>
  );
};

export default Features;
