import React, { useState, useEffect } from 'react';
import './style.css';
import {
  getUserBrews
  // , updateBrewOrder 
} from '../../api/api-helper'; // Correct path to the API helper
import BrewList from '../../components/BrewList/BrewList'; // Correct path to BrewList component
// import Button from '@mui/material/Button';

const MyBrewsPage = () => {
  const [brews, setBrews] = useState([]);
  const [
    // newOrder, 
    setNewOrder] = useState([]);
  const token = localStorage.getItem('idToken'); // Assuming you store the token in localStorage

  useEffect(() => {
    const fetchBrews = async () => {
      try {
        const data = await getUserBrews(token, true); // Fetching brews for admin
        const sortedBrews = data.brews.sort((a, b) => a.order - b.order);
        console.log("===sortedBrews==", sortedBrews)
        setBrews(sortedBrews);
      } catch (error) {
        console.error('Error fetching brews:', error);
      }
    };

    fetchBrews();
  }, [token]);

  const handleOrderChange = (newOrder) => {
    setNewOrder(newOrder);
  };

  // const handleSaveOrder = async () => {
  //   try {
  //     await updateBrewOrder(newOrder, token);
  //     alert('Brew order updated successfully!');
  //   } catch (error) {
  //     console.error('Error updating brew order:', error);
  //     alert('Failed to update brew order.');
  //   }
  // };

  return (
    <div className="my-brews-page">
      <h1>My Brews</h1>
      {/* {loading ? (
        // <p>Loading...</p>
      ) : ( */}
      <>
        <BrewList isViewOnly={false} brews={brews} onBrewAdded={() => { }} onOrderChange={handleOrderChange} />
        {/* <Button variant="contained" color="primary" onClick={handleSaveOrder} disabled={!newOrder.length}>
            Save Order
          </Button> */}
      </>
      {/* )} */}
    </div>
  );
};

export default MyBrewsPage;
