import React from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import add from 'src/Assets/images/Icons/add.svg';
import MainModal from '../MainModal';

export default function BrewModal({
  title = '',
  isOpen,
  onClose,
  children,
}) {

  return (
    <MainModal
      isOpen={isOpen} 
      onRequestClose={onClose}>
      <div className="brew-modal-component">
        <div className="close-btn">
          <ReactSVG src={add} className='icon-close' onClick={onClose} />
        </div>
        <div className='brew-main-content'>
          <div className='modal-header'>
            <h2>{title}</h2>
          </div>
          <div className='modal-body'>
            {children}
          </div>
        </div>
      </div>
    </MainModal>
  );
}
