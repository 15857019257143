import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import './Landing.css';

const Hero = () => {
  return (
    <div className="hero-section">
      <Container maxWidth="md">
        <Typography variant="h2" component="h1" gutterBottom className="hero-title">
          Manage your Brews with Ease
        </Typography>
        <Typography variant="h5" component="p" gutterBottom className="hero-subtitle">
          The all in one tool for documenting your brews and sharing them with the world
        </Typography>
        <Button variant="contained" color="primary" size="large" className="hero-button" to="/signup" component={RouterLink}>
          Get Started
        </Button>
      </Container>
    </div>
  );
};

export default Hero;
