import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getNoteDetails, updateNote, deleteNote } from '../../api/api-helper';
import './style.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';

const ViewNotesPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const brewId = queryParams.get('brewId');

  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newNote, setNewNote] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const token = localStorage.getItem('idToken'); // Ensure you use the correct key

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        if (token && brewId) {
          const data = await getNoteDetails(brewId, token);
          setNotes(data.notes);
        } else {
          console.error('No token found in localStorage or invalid brewId');
        }
      } catch (error) {
        console.error('Error fetching notes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotes();
  }, [token, brewId]);

  const handleAddNote = async () => {
    if (newNote.trim() === '') return;

    setLoading(true); // Start loading spinner

    try {
      const updatedNotes = [...notes, { noteId: `${Date.now()}`, note: newNote, date: new Date().toISOString() }];
      await updateNote(brewId, updatedNotes, [], token); // Pass empty array for deletedNoteIds
      setNotes(updatedNotes);
      setNewNote('');
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleNoteChange = (index, value) => {
    const updatedNotes = [...notes];
    updatedNotes[index].note = value;
    setNotes(updatedNotes);
  };

  const handleSaveNotes = async () => {
    setLoading(true); // Start loading spinner

    try {
      await updateNote(brewId, notes, [], token); // Pass empty array for deletedNoteIds
    } catch (error) {
      console.error('Error saving notes:', error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleDeleteNote = async (index) => {
    const noteId = notes[index].noteId;
    const updatedNotes = notes.filter((_, i) => i !== index);
    setNotes(updatedNotes);

    setLoading(true); // Start loading spinner

    try {
      await deleteNote(brewId, noteId, token); // Call the new deleteNote function
    } catch (error) {
      console.error('Error deleting note:', error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleSaveNote = async (index) => {
    setLoading(true); // Start loading spinner

    try {
      const updatedNotes = [...notes];
      await updateNote(brewId, updatedNotes, [], token); // Pass empty array for deletedNoteIds
      setEditingIndex(null);
    } catch (error) {
      console.error('Error saving note:', error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        Loading...
      </div>
    );
  }

  return (
    <div className="view-notes-page">
      <h1>Notes for Brew {brewId}</h1>
      <div className="notes-list">
        {notes.map((note, index) => (
          <div key={note.noteId} className="note-item">
            {editingIndex === index ? (
              <textarea
                value={note.note}
                onChange={(e) => handleNoteChange(index, e.target.value)}
              />
            ) : (
              <p>{note.note}</p>
            )}
            <div className="note-actions">
              {editingIndex === index ? (
                <IconButton onClick={() => handleSaveNote(index)}><SaveIcon /></IconButton>
              ) : (
                <IconButton onClick={() => setEditingIndex(index)}><EditIcon /></IconButton>
              )}
              <IconButton onClick={() => handleDeleteNote(index)}><DeleteIcon /></IconButton>
            </div>
            <div className="note-date">{new Date(note.date).toLocaleString()}</div>
          </div>
        ))}
      </div>
      <div className="add-note-section">
        <textarea
          placeholder="Add a new note..."
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />
        <button onClick={handleAddNote}>Add Note</button>
      </div>
      <button onClick={handleSaveNotes} className="save-notes-button">Save All Notes</button>
    </div>
  );
};

export default ViewNotesPage;
