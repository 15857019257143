import React from 'react';
import './style.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

export default function BrewItem({
  brew,
  isViewOnly,
  loadingImages,
  handleImageLoad,
  handleDelete
}) {

  const maxDescriptionLength = 175; // Maximum number of characters for the description

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  return (
    <div className="brew-item-component">
      <h2>{brew.name}</h2>
      <div className='brew-img-container'>
        {loadingImages[brew.brewId] !== false && (
          <CircularProgress />
        )}
        <img
          src={brew.image}
          alt={brew.name}
          className="brew-image"
          onLoad={() => handleImageLoad(brew.brewId)}
          style={{ display: loadingImages[brew.brewId] === false ? 'block' : 'none' }}
        />
        <div className="brew-details">
          <p>{truncateText(brew.description, maxDescriptionLength)}</p>
          <p><span>ABV:</span> {brew.abv ? brew.abv : ""}%</p>
          <p><span>Style:</span> {brew.style ? brew.style: ""}</p>
          <p><span>Availability:</span> {brew.availability ? brew.availability: 0}</p>
          {!isViewOnly && (
            <div className="delete-button-container">
              <IconButton onClick={() => handleDelete(brew.brewId)} style={{ color: 'red' }}>
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
