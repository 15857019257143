import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthToken } from './auth-utils';

const AuthRedirect = ({ children }) => {
  const token = getAuthToken();

  if (token) {
    return <Navigate to="/" />;
  }

  return children;
};

export default AuthRedirect;
