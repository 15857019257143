import React, { useState, useEffect } from 'react';
import './style.css';
import { useParams } from 'react-router-dom';
import { getBrewsByBreweryName } from '../../api/api-helper';
import BrewList from '../../components/BrewList/BrewList';

const ViewBrewsPage = () => {
  const { breweryName: encodedBreweryName } = useParams();
  const breweryName = decodeURIComponent(encodedBreweryName);
  const [brews, setBrews] = useState([]);

  useEffect(() => {
    const fetchBrews = async () => {
      try {
        console.log(breweryName) // Add this for debugging
        const data = await getBrewsByBreweryName(breweryName);
        const sortedBrews = data.brews.sort((a, b) => a.order - b.order);
        setBrews(sortedBrews);
      } catch (error) {
        console.error('Error fetching brews:', error);
      }
    };
    

    fetchBrews();
  }, [breweryName]);

  return (
    <div className="view-brews-page">
      <h1>{breweryName}</h1>
        <BrewList brews={brews} isViewOnly={true} breweryName={breweryName} />
    </div>
  );
};

export default ViewBrewsPage;
