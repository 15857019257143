import React from 'react';
import './style.scss';
import { Grid, Card } from '@mui/material'

export default function LastFeature({
  tag = '',
  title = '',
  description = '',
  logo
}) {

  return (
    <Grid item xs={12} md={4} className="last-feature-component">
      <Card className="feature-card">
        <div className='left-content'>
          <div className='tag-title'>
            <div className='tag'>{tag}</div>
            <h3>{title}</h3>
          </div>
          <p className='font-body'>{description}</p>
        </div>
        <div className='right-content'>
          <img
            src={logo}
            className='logo'
            alt='logo'
          />
          <div className="button learn-more-button">
            {/* <div>Learn more</div> */}
            {/* <div className='arrow-content'>
              <div className='arrow-right' />
            </div> */}
          </div>
        </div>
      </Card>
    </Grid>
  );
}
