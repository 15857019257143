export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  export const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
  
    return (
      password.length >= minLength &&
      hasNumber.test(password) &&
      hasSpecialChar.test(password) &&
      hasUpperCase.test(password) &&
      hasLowerCase.test(password)
    );
  };
  
  export const isEmpty = (str) => {
    return !str || str.trim() === '';
  };
  