import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';

const logoStyle = {
  height: '40px', // Set a fixed height to fit in the navbar
  cursor: 'pointer',
};

function NavBar({ mode, isAuthenticated, onSignOut }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <div >
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              height: '60px',
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <RouterLink to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                <img
                  src='/logo.png' // Ensure the logo is in the public folder
                  style={logoStyle}
                  alt="My Brew Page logo"
                />
                <Typography variant="h6" component="div" sx={{ fontFamily: 'Bangers, cursive', ml: 1, color: 'text.primary' }}>
                  My Brew Page
                </Typography>
              </RouterLink>
            </Box>
            {isAuthenticated ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={handleMenuOpen} color="inherit">
                  <Avatar alt="Profile Picture" src="/profile-pic.jpg" /> {/* Ensure profile-pic.jpg is in the public folder */}
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem component={RouterLink} to="/mybrews" onClick={handleMenuClose}>My Brews</MenuItem>
                  <MenuItem component={RouterLink} to="/mynotes" onClick={handleMenuClose}>My Notes</MenuItem>
                  <MenuItem component={RouterLink} to="/settings" onClick={handleMenuClose}>Settings</MenuItem>
                  <Divider />
                  <MenuItem onClick={() => { handleMenuClose(); onSignOut(); }}>Sign Out</MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  gap: 0.5,
                  alignItems: 'center',
                }}
              >
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  component={RouterLink}
                  to="/signin"
                >
                  Sign in
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component={RouterLink}
                  to="/signup"
                >
                  Get Started
                </Button>
              </Box>
            )}
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Divider />
                  {isAuthenticated ? (
                    <>
                      <MenuItem component={RouterLink} to="/mybrews" onClick={toggleDrawer(false)}>My Brews</MenuItem>
                      <MenuItem component={RouterLink} to="/mynotes" onClick={toggleDrawer(false)}>My Notes</MenuItem>
                      <MenuItem component={RouterLink} to="/settings" onClick={toggleDrawer(false)}>Settings</MenuItem>
                      <Divider />
                      <MenuItem onClick={() => { toggleDrawer(false); onSignOut(); }}>Sign Out</MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          component={RouterLink}
                          to="/signup"
                          sx={{ width: '100%' }}
                          onClick={toggleDrawer(false)}
                        >
                          Get Started
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="outlined"
                          component={RouterLink}
                          to="/signin"
                          sx={{ width: '100%' }}
                          onClick={toggleDrawer(false)}
                        >
                          Sign in
                        </Button>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar /> {/* Add this empty Toolbar to push content below the navbar */}
    </div>
  );
}

export default NavBar;
