import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getUserBrews = async (token) => {
  const response = await api.get('/getUserBrews', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getBrewsByBreweryName = async (breweryName) => {
  const response = await api.get(`/getUserBrews/${breweryName}`);
  return response.data;
};

export const uploadBrew = async (brewData, token) => {
  const response = await api.post('/upload', brewData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const deleteBrew = async (brewId, token) => {
  const response = await api.post('/delete', { brewId }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const updateBrewOrder = async (orderedBrews, token) => {
  const response = await api.put('/updateBrewOrder', { orderedBrews }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const generateBrewLogo = async (brewName, token) => {
  const response = await api.post('/generateBrewLogo', { brewName }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const generateBreweryName = async () => {
  const response = await api.get('/generateName');
  return response.data;
};


export const getNoteDetails = async (brewId, token) => {
  const response = await api.get('/notes', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      brewId,
    },
  });
  return response.data;
};

export const updateNote = async (brewId, notes, deletedNoteIds, token) => { // Updated to include deletedNoteIds
  const response = await api.put('/notes', { brewId, notes, deletedNoteIds }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const deleteNote = async (brewId, noteId, token) => {
  const response = await api.delete('/notes', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      brewId,
      noteId,
    },
  });
  return response.data;
};

