import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { poolData } from './cognito-config';

// Initialize the user pool
const userPool = new CognitoUserPool(poolData);

export const signUp = (email, password, name, breweryName) => {
  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, [{ Name: 'name', Value: name }, { Name: 'custom:breweryName', Value: breweryName }], null, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

export const signIn = (email, password) => {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const accessToken = result.getAccessToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        // Store the tokens in localStorage
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('username', email);

        // Retrieve user attributes
        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const userAttributes = {};
            attributes.forEach(attribute => {
              userAttributes[attribute.Name] = attribute.Value;
            });

            // Store custom attributes in localStorage
            localStorage.setItem('breweryName', userAttributes['custom:breweryName']);

            resolve({ idToken, accessToken, refreshToken, userAttributes });
          }
        });
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const refreshSession = () => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: localStorage.getItem('username'), // Store the username in localStorage on login
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

      cognitoUser.refreshSession(RefreshToken, (err, session) => {
        if (err) {
          reject(err);
        } else {
          const idToken = session.getIdToken().getJwtToken();
          const accessToken = session.getAccessToken().getJwtToken();

          localStorage.setItem('idToken', idToken);
          localStorage.setItem('accessToken', accessToken);

          resolve({ idToken, accessToken });
        }
      });
    } else {
      reject(new Error('No refresh token found'));
    }
  });
};

export const signOutUser = () => {
  const userData = {
    Username: localStorage.getItem('username'),
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  if (cognitoUser) {
    cognitoUser.signOut();
  }

  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('username');
  localStorage.removeItem('breweryName');
};

export const getUserAttributes = () => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: localStorage.getItem('username'), // Store the username in localStorage on login
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.getUserAttributes((err, attributes) => {
      if (err) {
        reject(err);
      } else {
        const userAttributes = {};
        attributes.forEach(attribute => {
          userAttributes[attribute.Name] = attribute.Value;
        });
        resolve(userAttributes);
      }
    });
  });
};
