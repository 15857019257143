import React, { useState, useEffect, useCallback } from 'react';
import { uploadBrew } from '../../api/api-helper';
import { Box, Button, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
import { DropDownSelect, FormInput } from '../Common';

const styleList = [
  "Beer", "Mead", "Wine", "Kombucha"
];

const AddBrewForm = ({ onBrewAdded, onClose }) => {
  const [brewName, setBrewName] = useState('');
  const [description, setDescription] = useState('');
  const [availability, setAvailability] = useState('');
  const [abv, setAbv] = useState('');
  const [ibu, setIbu] = useState('');
  const [style, setStyle] = useState('');
  const [volume, setVolume] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('idToken'); // Ensure you use the correct key

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const brewData = {
      name: brewName,
      description,
      availability,
      abv,
      ibu,
      style,
      volume,
      imageBase64: image ? await toBase64(image) : null,
    };

    try {
      const newBrew = await uploadBrew(brewData, token);
      onBrewAdded(newBrew);
      setBrewName('');
      setDescription('');
      setAvailability('');
      setAbv('');
      setIbu('');
      setStyle('');
      setVolume('');
      setImage(null);
      setImagePreview(null);
      onClose();
    } catch (error) {
      console.error('Error uploading brew:', error);
    } finally {
      setLoading(false);
    }
  };

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log("file", file)
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImagePreview(null);
  };

  const handleClose = useCallback(() => {
    if (brewName || description || availability || abv || ibu || style || volume || image) {
      if (window.confirm('Are you sure you want to exit? Your draft will not be saved.')) {
        onClose();
      }
    } else {
      onClose();
    }
  }, [brewName, description, availability, abv, ibu, style, volume, image, onClose]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const changeAvailability = (value) => {
    if (value === '' || /^\d+$/.test(value)) {
      setAvailability(value);
    }
  };

  const changeABV = (value) => {
    const numericValue = parseFloat(value);

    // Check if the value is a number and between 0 and 100
    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
      setAbv(value);
    } else if (value === '') {
      // Allow clearing the input
      setAbv('');
    }
  };

  return (
    <Box component="form" className='add-brew-form-component' onSubmit={handleSubmit}>
      <FormInput
        label="Name *"
        required
        value={brewName}
        onChange={v => setBrewName(v)}
      />
      <FormInput
        label="Description *"
        required
        type="textarea"
        value={description}
        onChange={v => setDescription(v)}
      />
      <div className='inline'>
        <FormInput
          label="Availability *"
          type='number'
          required
          value={availability}
          onChange={v => changeAvailability(v)}
        />
        <DropDownSelect
          label={"Style"}
          value={style}
          dropListInfo={styleList}
          onSelect={e => setStyle(e)}
        />
      </div>
      <div className='inline'>
        <FormInput
          label="ABV (%)"
          value={abv}
          onChange={v => changeABV(v)}
        />
        <FormInput
          label="IBU"
          value={ibu}
          onChange={v => setIbu(v)}
        />     
        <FormInput
          label="Volume (ml)"
          value={volume}
          onChange={v => setVolume(v)}
        />
      </div>
      <Button variant="contained" className='upload-btn' component="label" fullWidth sx={{ mt: 2, mb: 2 }}>
        Upload Image
        <input type="file" hidden onChange={handleImageChange} />
      </Button>
      {imagePreview && (
        <div className="image-preview">
          <img src={imagePreview} alt="Preview" className="image-preview-img" />
          <IconButton onClick={handleRemoveImage} className="image-preview-remove">
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <Button className='submit-btn' type="submit" variant="contained" fullWidth disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Add Brew'}
      </Button>
    </Box>
  );
};

export default AddBrewForm;
