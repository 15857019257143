import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container, Box, Typography, TextField, Button, Grid, Link, CssBaseline, Alert,
  Stepper, Step, StepLabel, createTheme, ThemeProvider
} from '@mui/material';
import { signUp, signIn } from '../../auth/cognito-utils';
import SignUpSuccess from './SignUpSuccess';
import { validateEmail, validatePassword, isEmpty } from './validation';
import { generateBreweryName } from '../../api/api-helper';

const theme = createTheme();

function Login({ isSignUp }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [breweryName, setBreweryName] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const steps = ['Enter User Details', 'Generate Free Brewery Name'];

  const handleNext = async () => {
    const validationErrors = {};

    if (activeStep === 0) {
      if (isSignUp && isEmpty(name)) {
        validationErrors.name = 'Name is required.';
      }
      if (isEmpty(email) || !validateEmail(email)) {
        validationErrors.email = 'Please enter a valid email address.';
      }
      if (isEmpty(password) || !validatePassword(password)) {
        validationErrors.password = 'Password must be at least 8 characters long and include at least one number, one special character, one uppercase letter, and one lowercase letter.';
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (activeStep === 0 && isSignUp) {
      try {
        const response = await generateBreweryName();
        setBreweryName(response.breweryName);
      } catch (error) {
        setMessage('Failed to generate brewery name. Please try again.');
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setErrors({});

    if (isSignUp) {
      try {
        await signUp(email, password, name, breweryName);
        setSuccess(true);
      } catch (error) {
        if (error.code === 'UsernameExistsException') {
          setMessage('An account with this email already exists. Try signing in instead.');
        } else {
          setMessage('Sign Up failed. Please refresh the page and try again.');
        }
      }
    } else {
      try {
        const token = await signIn(email, password);
        localStorage.setItem('authToken', token);
        navigate('/');
      } catch (error) {
        if (error.code === 'UserNotFoundException') {
          setMessage('No account found with this email. Please sign up first.');
        } else if (error.code === 'NotAuthorizedException') {
          setMessage('Incorrect email or password. Please try again.');
        } else {
          setMessage('Sign In failed. Please refresh the page and try again.');
        }
      }
    }
  };

  const handleSignInRedirect = () => {
    // Implement redirect to sign-in page if needed
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 12,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {success ? (
            <SignUpSuccess onSignIn={handleSignInRedirect} />
          ) : (
            <>
              <Typography component="h1" variant="h5">
                {isSignUp ? 'Sign Up' : 'Sign In'}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                {isSignUp ? (
                  <>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    {activeStep === 0 && (
                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <TextField
                            name="name"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            autoFocus
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={Boolean(errors.password)}
                            helperText={errors.password}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {activeStep === 1 && (
                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <TextField
                            name="breweryName"
                            required
                            disabled
                            fullWidth
                            id="breweryName"
                            label="Brewery Name"
                            value={breweryName}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          error={Boolean(errors.email)}
                          helperText={errors.email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          error={Boolean(errors.password)}
                          helperText={errors.password}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleSubmit}
                    >
                      Sign In
                    </Button>
                  </>
                )}
                {message && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {message}
                  </Alert>
                )}
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href={isSignUp ? '/signin' : '/signup'} variant="body2">
                      {isSignUp
                        ? 'Already have an account? Sign in'
                        : "Don't have an account? Sign up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

Login.propTypes = {
  isSignUp: PropTypes.bool.isRequired,
};

export default Login;
