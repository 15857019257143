import React from 'react';
import './style.scss';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Hero, Features
  // , Brands, BrewWorks 
} from 'src/components';

function LandingPage() {
  return (
    <div className='landing-page'>
      <Hero />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Features />
        <Divider />
        {/* <Brands /> */}
        {/* <BrewWorks /> */}
      </Box>
    </div>
  );
}

export default LandingPage;
