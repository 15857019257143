import React, { useState } from 'react';
import './style.scss';

export default function FormInput({
  className = '',
  largeSpacing = false,
  placeholder,
  type = "text",
  error = '',
  label = true,
  required = false,
  maxLength = undefined,
  value,
  onChange,
}) {

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className={`form-input-component ${className}`}>
      {label ? <div className='input-label'>{label}</div> : null}
      <div className={`input-container ${type === 'textarea' ? 'textarea' : ''} ${isFocused ? 'focus' : ''} ${value ? 'active' : ''}`} >
        {type === 'textarea' ? (
          <>
            <textarea
              className={`main-input ${largeSpacing ? 'large-spacing' : ''}`}
              placeholder={placeholder}
              onFocus={handleFocus}
              onBlur={handleBlur}
              maxLength={maxLength}
              required={required}
              value={value}
              onChange={e => onChange(e.currentTarget.value)}
              rows={3}
              style={{ resize: 'none' }} // Prevent textarea resizing
            />
            <div className='char-counter'>
              {value.length}/{maxLength} characters
            </div>
          </>
        ) : (
          <input
            type={type}
            className={`main-input ${largeSpacing ? 'large-spacing' : ''}`}
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            maxLength={maxLength}
            required={required}
            value={value}
            onChange={e => onChange(e.currentTarget.value)}
          />
        )}
      </div>
      {(value && !isFocused) ? <div className='error'>{error}</div> : null}
    </div>
  );
}
