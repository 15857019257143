import React from 'react';
// import GenerateBrewLogo from '../../components/GenerateBrewLogo/GenerateBrewLogo';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const GenerateBrewLogoPage = () => {
  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Generate Brew Logo
      </Typography>
      {/* <GenerateBrewLogo /> */}
    </Box>
  );
};

export default GenerateBrewLogoPage;
