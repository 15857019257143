import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const SortableItem = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 'auto', // Ensure the dragged item is on top
    opacity: isDragging ? 0.5 : 1, // Change opacity for better visual feedback
    boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none', // Add shadow during dragging
    ...props.style,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  );
};

export const arrayMove = (array, from, to) => {
  const newArray = array.slice();
  const [removed] = newArray.splice(from, 1);
  newArray.splice(to, 0, removed);
  return newArray;
};
