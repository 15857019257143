import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const SignUpSuccess = ({ onSignIn }) => {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 12,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up Successful
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          Please check your email to verify your account.
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={onSignIn}
          component={RouterLink}
          to="/signin"
        >
          Go to Sign In
        </Button>
      </Box>
    </Container>
  );
};

export default SignUpSuccess;
