import React from 'react';
import './style.scss';

export default function AddBrewBtn({
  handleAddBrewClick,
}) {

  return (
    <div className='add-brew-btn-component' onClick={handleAddBrewClick}>
      <div className="plus-icon">+</div>
    </div>
  );
}
